import {createElement, Fragment, useEffect, useState} from "react";
import {Breadcrumbs, ContainerLayout} from "@variocube/app-ui";
import {gs} from "../../theme";
import {Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Grid, Theme, Typography} from "@mui/material";
import {Bookmark, DispatchType} from "../../domain/Bookmark";
import {BookmarksProvider} from "../../domain/BookmarksProvider";
import {useTenantId, useTenantUser, useTenantUserRole} from "../TenantContextProvider";
import {Loading} from "../Loading";
import {Business, DeleteForever, Email, InsertDriveFile, LocalLaundryService, Person} from "@mui/icons-material";
import {makeStyles} from "@mui/styles";
import {SiteSelectInput} from "../SiteSelectInput";
import {useLocalization} from "../../i18n";
import {HelmetTitleWrapper} from "../HelmetTitleWrapper";
import {useAsync} from "react-async-hook";
import {Link} from "react-router-dom";
import {UndrawEmpty} from "@variocube/app-ui/esm/content-table/UndrawEmpty";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";

export function DispatchBookmarks() {
    const {t} = useLocalization();
    const tenantId = useTenantId();
    const user = useTenantUser();
    const {isAdmin} = useTenantUserRole();

    const [filterSiteId, setFilterSiteId] = useState<string>();

    const {loading, result: allBookmarks} = useAsync(() => BookmarksProvider.list(tenantId), [tenantId]);

    useEffect(() => {
        setFilterSiteId(user?.siteId);
    }, [user]);

    const bookmarks = allBookmarks?.filter(x => !filterSiteId || x.siteId === filterSiteId);

    return (
        <ContainerLayout>
            <HelmetTitleWrapper pageTitle={t('dispatch.plural')} />
            <Grid container spacing={gs}>
				<Grid item xs={12}>
					<Breadcrumbs>
						<BreadcrumbRouterLink to={`/${tenantId}/dispatch`}>
							{t("dispatch.plural")}
						</BreadcrumbRouterLink>
					</Breadcrumbs>
				</Grid>
                <Grid item xs={12}>
                    <Grid container spacing={gs} alignItems="center">
                        <Grid item style={{flexGrow: 1}}>
                            <Typography variant="h2">{t('dispatch.plural')}</Typography>
                        </Grid>
                        {isAdmin && (
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component={Link}
                                    to={`/${tenantId}/dispatch/create`}
                                >
                                    {t('dispatch.create')}
                                </Button>
                            </Grid>
                        )}
                        <Grid item>
                            <SiteSelectInput
                                label={t('sites.filter')}
                                value={filterSiteId}
                                onChange={s => setFilterSiteId(s?.siteId)}
                                allowSelectAll
								disabled = {!isAdmin}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {loading && <Loading />}
                    {!loading && bookmarks &&
                        <Fragment>
                            {bookmarks.length == 0 && <NoBookmarks />}
                            {bookmarks.length > 0 && <BookmarkCards bookmarks={bookmarks} />}
                        </Fragment>
                    }
                </Grid>
            </Grid>
        </ContainerLayout>
    );
}

function NoBookmarks() {
    const {t} = useLocalization();
    return (
		<span>
			 <UndrawEmpty height="30%" width="auto" />
			  <Typography variant="h3"  align="center"  >{t('dispatch.noDispatchOptions')}</Typography>
		</span>);
}

interface BookmarkCardsProps {
    bookmarks: Bookmark[];
}

function BookmarkCards({bookmarks}: BookmarkCardsProps) {
    const {t} = useLocalization();
    const {isAdmin} = useTenantUserRole();
    const tenantId = useTenantId();
    const classes = useStyles();

    return (
        <Grid container spacing={gs}>
            {bookmarks.sort((a,b) => a.label.localeCompare(b.label)).map((b) =>
                <Grid key={b.id} item lg={3} md={4} sm={6} xs={12}>
                    <Card>
                        <CardHeader
                            avatar={<Avatar><BookmarkIcon bookmarkType={b.type} /></Avatar>}
                            title={b.label} />
                        <CardContent className={classes.cardContent}>
                            <Typography variant="body2" component="div">
                                <p dangerouslySetInnerHTML={{__html: b.description}} />
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Box flexGrow={1} />
                            {isAdmin &&
                                <Fragment>
                                    <Button
                                        size="small"
                                        component={Link}
                                        to={`/${tenantId}/dispatch/${b.id}/edit`}
                                    >
                                        {t('edit')}
                                    </Button>
                                </Fragment>
                            }
                            <Button
                                size="small"
                                color="primary"
                                component={Link}
                                to={`/${tenantId}/dispatch/${b.id}`}
                            >
                                {t('dispatch.select')}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            )}
        </Grid>
    );
}

function BookmarkIcon({bookmarkType}: {bookmarkType: DispatchType}) {
    switch (bookmarkType) {
        case DispatchType.DryCleaning:
            return (<LocalLaundryService />);
        case DispatchType.Department:
            return (<Business />);
        case DispatchType.Person:
            return (<Person />);
        case DispatchType.DocumentDestruction:
            return (<DeleteForever />);
        case DispatchType.Outbound:
            return (<Email />);
        default:
            return (<InsertDriveFile />);
    }
}

const useStyles = makeStyles((theme: Theme) => ({
    cardContent: {
        height: '7em',
        maxHeight: '7em',
        overflowY: 'hidden',
        maskImage: 'linear-gradient(to bottom, black 50%, transparent 100%)',
        "& p": {
            marginBlockStart: 0
        }
    }
}));
