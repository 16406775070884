import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {createElement, useState} from "react";
import {Site} from "../../domain/Site";
import {useLocalization} from "../../i18n";
import * as React from "react";
import {SiteSelectInput} from "../SiteSelectInput";
import {SiteUsageStats} from "./Sites";

interface SiteReconnectProps {
	open: boolean;
	site: Site;
	sites: Site[]
	usage: SiteUsageStats;
	onCancel: () => void;
	onCommit: (siteId: string) => void;
}

export function SiteReconnectDialog(props: SiteReconnectProps) {
	const {t} = useLocalization();
	const [ newSite, setNewSite] = useState<string | undefined>("");
	return (
		<Dialog open={props.open}>
			<DialogTitle>
				{t("sites.delete")}
			</DialogTitle>
			<DialogContent>
				{t("sites.deleteReconnectPrefix")}
				<ul>
					{ props.usage.deliveries > 0 &&
						<li>
							{props.usage.deliveries} {t("deliveries.plural")}
						</li>
					}
					{ props.usage.user > 0 &&
						<li>
							{props.usage.user} {t("users.plural")}
						</li>
					}
					{ props.usage.bookmarks  > 0 &&
						<li>
							{props.usage.bookmarks} {t("dispatch.plural")}
						</li>
					}
					{ props.usage.cubes  > 0 &&
						<li>
							{props.usage.cubes} {t("cubes.plural")}
						</li>
					}
					{ props.usage.notificationTemplates  > 0 &&
						<li>
							{props.usage.notificationTemplates} {t("tenants.customTemplates")}
						</li>
					}
				</ul>
				{t("sites.deleteReconnectSuffix")}
				<br/><br/>
				{t("sites.reconnect")}
				<br/>
				<br/>
				<Typography>
					<strong>{t("warning.title")}</strong>
				</Typography>
				<Typography>{t("warning.hint")}</Typography>
				<br/>
				<SiteSelectInput value={newSite}  onChange={ (site) => { setNewSite(site?.siteId);}}
				 label ={t("sites.newSite")} sites={ props.sites.filter(s => s.enabled  && s?.siteId != props.site?.siteId)}
				/>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" color="primary" onClick={ () => {
					props.onCommit(newSite ||  "") }
					} disabled={!newSite}>
					{t("yes")}
				</Button>
				<Button onClick= {props.onCancel  } >
					{t("no") }
			    </Button>
			</DialogActions>
		</Dialog>
	);
}
