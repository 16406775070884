import {createElement, useState} from "react";
import {Delivery} from "../../domain/Delivery";
import {Chip} from "@mui/material";
import {shorten} from "../../tools";
import {red} from "@mui/material/colors";
import {useTenantId} from "../TenantContextProvider";
import {Link} from "react-router-dom";
import {OverridableStringUnion} from "@mui/types";
import {ChipPropsColorOverrides} from "@mui/material/Chip/Chip";

interface DeliveryLinkProps {
    deliveryId?: string;
    delivery: Delivery;
    maxLen?: number;
	color?: OverridableStringUnion<
		'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
		ChipPropsColorOverrides
	>;
}

export const DeliveryLink = ({ delivery, maxLen, color}: DeliveryLinkProps) => {
    const tenantId = useTenantId(); 
    const [error, setError] = useState<string>();

    const chipLabel = (delivery: Delivery) => {
        const orderDescription = delivery.order ? delivery.order.description : undefined;
        const recipients = (delivery.recipients && delivery.recipients.length > 0) ?
            delivery.recipients.map((r) => r.recipientName).join(', ') :
            undefined;
        if (orderDescription && recipients) {
            return `${orderDescription}: ${recipients}`;
        } else if (orderDescription) {
            return orderDescription;
        } else if (recipients) {
            return recipients;
        }
        return delivery.delivery.deliveryId;
    }

    if (delivery && delivery.delivery) {
        let label = chipLabel(delivery);
        if (maxLen) label = shorten(label, maxLen);
        return (
            <Chip
                style={{cursor: 'pointer'}}
				color={color}
                label={label}
                component={Link}
                to={`/${tenantId}/deliveries/${delivery.delivery.deliveryId}`}
            />
        );
    }
    if (error) {
        return (
            <Chip style={{backgroundColor: red[800], color: '#fff'}} label={error.toString()} />
        );
    }
    return (<span>...</span>);
}
