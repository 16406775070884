import * as React from "react";
import {createElement} from "react";
import {useNavigate} from "react-router";
import {useLocalization} from "../../i18n";
import {useRequiredParam} from "../../useRequiredParam";
import {usePrintViewConfig, useTenant, useTenantId, useTenantUser, useTenantUserRole} from "../TenantContextProvider";
import {DeliveryDetailsLegacyComponent} from "./DeliveryDetailsLegacyComponent";

export function DeliveryView() {
	const navigate = useNavigate();
	const languageContextData = useLocalization();
	const tenantId = useTenantId();
	const deliveryId = useRequiredParam("deliveryId");
	const user = useTenantUser();
	const tenant = useTenant();
	const {isUser, isAdmin, isAdminLight, isSuperUser, isRecipient} = useTenantUserRole();
	const printViewConfig = usePrintViewConfig();

	return (
		<DeliveryDetailsLegacyComponent
			tenantId={tenantId}
			deliveryId={deliveryId!}
			navigate={navigate}
			tenant={tenant}
			user={user}
			isRecipient={isRecipient}
			isUser={isUser}
			isAdmin={isAdmin}
			isAdminLight={isAdminLight}
			isSuperUser={isSuperUser}
			printViewConfig={printViewConfig}
			languageContext={languageContextData}
		/>
	);
}
