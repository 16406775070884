import {ExpandMore} from "@mui/icons-material";
import {Alert} from "@mui/lab";
import {
	Button,
	ButtonGroup,
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Menu,
	MenuItem,
	Snackbar,
	Stack,
	Typography,
} from "@mui/material";
import {BreadcrumbItem, Breadcrumbs} from "@variocube/app-ui";
import {ContainerLayout} from "@variocube/app-ui";
import {NotFound} from "@variocube/app-ui/esm/layout/NotFound";
import {createElement, useCallback, useState} from "react";
import {useAsync} from "react-async-hook";
import {useNavigate} from "react-router";

import {Link} from "react-router-dom";
import {authCredentials} from "../../auth/authCredentials";
import {TenantUserProvider} from "../../domain/TenantUserProvider";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {useRequiredParam} from "../../useRequiredParam";
import {ActionsMenu} from "../ActionsMenu";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";
import {HelmetTitleWrapper} from "../HelmetTitleWrapper";
import {Loading} from "../Loading";
import {SimpleTextInput} from "../SimpleTextInput";
import {useTenantId, useTenantUser} from "../TenantContextProvider";
import {UserDisplay} from "./UserDisplay";

export function UserView() {
	const username = useRequiredParam("username");
	const tenantId = useTenantId();
	const me = useTenantUser();
	const {t} = useLocalization();
	let navigate = useNavigate();

	const [open, setOpen] = useState<boolean>(false);

	const [nextPassword, setNextPassword] = useState<string>("");
	const [confirmPassword, setConfirmPassword] = useState<string>("");

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>();

	const {result: user, error} = useAsync(() => TenantUserProvider.get(tenantId, username), [tenantId, username]);

	const showMenu = useCallback((anchorEl: HTMLButtonElement | null) => {
		setAnchorEl(anchorEl);
	}, [setAnchorEl]);

	const onCancel = useCallback(() => setOpen(false), [setOpen]);

	const onSave = useCallback(async () => {
		if (user) {
			await TenantUserProvider.changeUserPassword(tenantId, user.username, nextPassword);
			if (me.username === user.username) {
				authCredentials.login(user.username, nextPassword);
			}
		}
		onCancel();
	}, [tenantId, user, me, nextPassword, onCancel]);

	if (error) {
		return (
			<NotFound errorMsg={t("notFound.title")} backToHomeMsg={t("notFound.home")} pathMsg={t("notFound.info")} />
		);
	}

	return (
		<ContainerLayout>
			<HelmetTitleWrapper pageTitle={t("users.plural")} />
			<Stack spacing={gs} direction="column">
				<Breadcrumbs>
					<BreadcrumbRouterLink to={`/${tenantId}/users`}>{t("users.plural")}</BreadcrumbRouterLink>
					<BreadcrumbItem>{user?.username}</BreadcrumbItem>
				</Breadcrumbs>
				<Stack spacing={gs} direction="row">
					<Typography variant="h2" flex={1}>{t("settings.user.userData")}</Typography>
					<ActionsMenu
						actions={[
							{label: t("edit"), onClick: () => navigate(`/${tenantId}/users/${username}/edit`)},
							{label: t("settings.user.changePassword"), onClick: () => setOpen(true)},
						]}
					/>
					<Snackbar>
						<Alert severity="success">{t("deliveries.triggerCallback.success")}</Alert>
					</Snackbar>
				</Stack>
				{user && (
					<Card>
						<CardContent>
							<UserDisplay user={user} />
						</CardContent>
					</Card>
				)}
				{!user && <Loading />}
			</Stack>
			<Dialog maxWidth="sm" open={open} onClose={() => onCancel()}>
				<DialogTitle>{t("users.setPassword.title")}</DialogTitle>
				<DialogContent>
					<Grid container spacing={gs}>
						<Grid item xs={12}>
							<SimpleTextInput
								label={t("users.changePassword.nextPassword")}
								type="password"
								value={nextPassword}
								onChange={setNextPassword}
								error={!Boolean(nextPassword)}
							/>
						</Grid>
						<Grid item xs={12}>
							<SimpleTextInput
								label={t("users.changePassword.confirmPasswort")}
								type="password"
								value={confirmPassword}
								onChange={setConfirmPassword}
								error={nextPassword != confirmPassword}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={() => onCancel()}>{t("cancel")}</Button>
					<Button
						disabled={nextPassword.length == 0 || nextPassword != confirmPassword}
						color="primary"
						variant="contained"
						onClick={() => onSave()}
					>
						{t("save")}
					</Button>
				</DialogActions>
			</Dialog>
		</ContainerLayout>
	);
}
