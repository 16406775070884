import { Fragment, createElement, useCallback, useEffect, useMemo, useState } from "react";
import {
    Badge,
    Box,
    Button, Grid,
    IconButton,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    debounce,
    useTheme
} from "@mui/material";
import { NotificationsActive, NotificationsOff, Search } from "@mui/icons-material";
import { Loading } from "../Loading";
import { User } from "../../domain/TenantUser";
import { useTenant, useTenantId } from "../TenantContextProvider";
import { TenantUserProvider } from "../../domain/TenantUserProvider";
import { RoleDisplay } from "./RoleDisplay";
import { Roles } from "../../domain/Roles";
import { useLocalization } from "../../i18n";
import { HelmetTitleWrapper } from "../HelmetTitleWrapper";
import { gs } from "../../theme";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useAsync } from "react-async-hook";
import { Breadcrumbs, ColumnType, ContainerLayout, ContentTable, Page, PagingSettings } from "@variocube/app-ui";
import { BreadcrumbRouterLink } from "../BreadcrumbRouterLink";
import { usersPaging } from "../pagings";
import {usersColumns} from "../uis";



export function UsersList() {
    const { t } = useLocalization();
    const tenantId = useTenantId();
    const tenant = useTenant();
    const [page, setPage] = useState<Page<User>>();
    const [searchFor, setSearchFor] = useState<string>("");
    const [pageable, setPageable] = useState<PagingSettings<keyof typeof baseColumns>>(usersPaging.getSettings());
	const [inProgress, setInProgress] = useState(false);
	const [columns, setColumns] = useState<ColumnType>();


    const loadPage = useCallback(() => {
		setInProgress(true);
		TenantUserProvider.listTenantUsersByPage(tenantId, usersPaging)
			.then((users) => {
				setPage(users);
			})
			.finally(() => setInProgress(false));
	}, [tenantId]);

    useEffect(() => {
		let columns = usersColumns.get();
		if (columns == null) {
			usersColumns.set(baseColumns);
			columns = baseColumns;
		}
		setColumns(columns);
		loadPage();
	}, [loadPage, tenant]);

    const handleSearchDebounced: (arg0: string) => void = useCallback(
		debounce((searchFor: string) => {
			handleSearch(searchFor);
		}, 300),
		[],
	);



    const baseColumns: ColumnType = useMemo<ColumnType>(() => ({
        "fullname": {show: true, name: t("users.fullName")},
        "username": {show: true, name: t("users.username")},
        "email": {show: true, name: t("users.email")},
        "roles": {show: true, name: t("users.role")},
    }), [t]);

    function search(searchFor: string) {
		setInProgress(true);
		TenantUserProvider.search(tenantId, searchFor, usersPaging)
			.then((users) => {
				setPage(users);
			})
			.finally(() => setInProgress(false));
	}

	function handleSearch(searchMe: string) {
		let newPageable = {...pageable, pageNumber: 0};
		usersPaging.updateSettings(newPageable);
		setPageable(newPageable);
		search(searchMe);
	}

    const handleColumnsChange = useCallback((c: ColumnType) => {
		setColumns(c);
		usersColumns.set(c);
	}, [tenant]);


    const handlePagingChange = (pageable: PagingSettings<any>) => {
		usersPaging.updateSettings(pageable);
		setPageable(pageable);
		search(searchFor);
	};

    return (
        <ContainerLayout>
            <HelmetTitleWrapper pageTitle={t('users.plural')} />
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <Breadcrumbs>
                        <BreadcrumbRouterLink to={`/${tenantId}/users`}>{t("users.plural")}</BreadcrumbRouterLink>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={gs}>
                        <Grid item style={{ flexGrow: 1 }}>
                            <Typography variant="h2">{t('users.plural')}</Typography>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" component={RouterLink} to={`/${tenantId}/users/new`}>
                                {t('users.create.title')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
					<TextField
						variant="outlined"
						fullWidth
						value={searchFor}
						onChange={(e) => {
							const value = e.target.value;
							setSearchFor(value);
							handleSearchDebounced(value);
						}}
						onKeyDown={(e) => {
							if (e.key == "Enter") {
								handleSearchDebounced(searchFor);
							}
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={() => handleSearchDebounced(searchFor)}>
										<Search />
									</IconButton>
								</InputAdornment>
							),
						}}
						label={t("users.searchTerms.label")}
						placeholder={t("users.searchTerms.placeholder")}
					/>
				</Grid>
                <Grid item xs={12}>
                    <Paper>
                        {page == undefined && <Box p={4}><Loading /></Box>}
                        {page?.content != undefined && columns && pageable && <UsersTable handleColumnsChange={handleColumnsChange} handlePagingChange={handlePagingChange}  pageable={pageable} page={page} inProgress={inProgress} columns={columns} />}
                    </Paper>
                </Grid>
            </Grid>
        </ContainerLayout>
    );
}

interface UsersTableProps {
    pageable: PagingSettings<string | number>,
    page: Page<User>,
    inProgress: boolean,
    columns: ColumnType,
    handlePagingChange : (pageable: PagingSettings<any>) => void,
    handleColumnsChange: (c: ColumnType) => void
}

function UsersTable(props: UsersTableProps) {
    const { t } = useLocalization();
    const page = props.page;
    page.content = props.page.content;

    return (
        <div>
            <ContentTable
                pageable={props.pageable}
                page={props.page}
                inProgress={props.inProgress}
                columns={props.columns}
                onPageableChange={props.handlePagingChange}
                onColumnsChange={props.handleColumnsChange}
                renderTableBody={
                    <Fragment>
                        {props.page.content.map((r, i) => (
                            <UserRow key={i} user={r} columns={props.columns} />
                        ))}
                    </Fragment>
                }
            />
        </div>
    );
}

interface UserRowProps {
    user: User;
    columns: ColumnType;
}

function UserRow(props: UserRowProps) {
    const { user, columns } = props;
    const navigate = useNavigate();
    const tenantId = useTenantId();
    const theme = useTheme();

    const isColumnActive = useCallback((name: string) => (columns[name] && columns[name].show), [columns]);

    return (
        <TableRow hover onClick={() => navigate(`/${tenantId}/users/${user.username}`)} sx={{ cursor: "pointer" }}>
            {isColumnActive("fullname") && <TableCell>{`${user.fullname}`}</TableCell>}
            {isColumnActive("username") && <TableCell>{user.username}</TableCell>}
            {isColumnActive("email") &&
                <TableCell>
                    <span>{user.email}</span>
                    <Badge style={{ paddingLeft: theme.spacing(1) }}>
                        {user.receiveEmails ? <NotificationsActive fontSize={"small"} /> : <NotificationsOff fontSize={"small"} color="disabled" />}
                    </Badge>
                </TableCell>}
            {isColumnActive("roles") && <TableCell><RoleDisplay role={user.roles as Roles} size={'medium'} /></TableCell>}
        </TableRow>
    );
}
