import {
	Dialog,
	DialogContent,
	DialogTitle,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import * as React from "react";
import {createElement} from "react";
import {TenantManualHandoverOptions} from "../../domain/TenantUser";
import {useLocalization} from "../../i18n";
import {useTenant} from "../TenantContextProvider";
import {ManualHandover} from "./ManualHandover";

interface ManualHandoverDialogProps {
	tenantId: string;
	deliveryIds: string[];
	open: boolean;
	onCancel: () => void;
	onConfirm: () => void;
	options: TenantManualHandoverOptions;
	orderDescription: string;
}

export function ManualHandoverDialog(props: ManualHandoverDialogProps) {
	const {t} = useLocalization();
	const tenant = useTenant();

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Dialog fullScreen={fullScreen} open={props.open}>
			<DialogTitle>{t("deliveries.manualHandover.title")}</DialogTitle>
			<DialogContent>
				<ManualHandover
					tenantId={tenant.centerId}
					deliveryIds={props.deliveryIds}
					onCancel={props.onCancel}
					onConfirm={props.onConfirm}
					options={tenant.manualHandoverOptions}
					orderDescription={props.deliveryIds[0]}
				/>
			</DialogContent>
		</Dialog>
	);
}
