import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {Box, Button, Card, CardActions, CardContent, Link, Stack, Typography} from "@mui/material";
import {ConfirmDialog, TextField} from "@variocube/app-ui";
import {createElement, Fragment, useState} from "react";
import {TenantOAuth2} from "../../domain/TenantUser";
import {TenantUserProvider} from "../../domain/TenantUserProvider";
import {useLocalization} from "../../i18n";
import {LabeledItem} from "../LabeledItem";
import {useTenantId} from "../TenantContextProvider";

export function OAuth2EmailSenderSetting({oauth2: pOauth2}: { oauth2: TenantOAuth2 }) {
	const {t} = useLocalization();
	const tenantId = useTenantId();

	const [editMode, setEditMode] = useState(false);
	const [removalPrompt, setRemovalPrompt] = useState(false);
	const [oauth2, setOauth2] = useState<TenantOAuth2>(pOauth2);

	function handleEditModeOpen() {
		setEditMode(true);
	}

	function handleEditModeClose() {
		setOauth2(pOauth2);
		setEditMode(false);
	}

	function handleOauth2Change(name: keyof TenantOAuth2, value: string) {
		setOauth2({
			...oauth2,
			[name]: value
		})
	}

	async function handleRemoval() {
		try {
			await TenantUserProvider.removeOAuth2Settings(tenantId);
			setOauth2({});
			setRemovalPrompt(false);
		} catch (err) {
			console.error('failed to remove oauth2 settings', err);
		}
	}

	async function handleSave() {
		try {
			await TenantUserProvider.updateOAuth2Settings(tenantId, oauth2);
			setEditMode(false);
		} catch (err) {
			console.error("failed to update oauth2 settings", err);
		}
	}

	const oauth2Configured = !!oauth2.entraId && !!oauth2.clientId && !!oauth2.clientSecret && !!oauth2.smtpUser;
	return (
		<Card>
			<CardContent>
				<Typography variant="h5">{t("settings.oauth2.title")}</Typography>
				<Box my={3} />
				{!editMode && (
					<Stack direction="column" spacing={2}>
						<LabeledItem label={t("settings.oauth2.entraId")}>
							{oauth2.entraId || "-"}
						</LabeledItem>
						<LabeledItem label="Client ID">
							{oauth2.clientId || '-'}
						</LabeledItem>
						<LabeledItem label="Client Secret">
							{oauth2.clientSecret || '-'}
						</LabeledItem>
						<LabeledItem label={t('tenants.emailSender')}>
							{oauth2.smtpUser || '-'}
						</LabeledItem>
					</Stack>
				)}
				{editMode && (
					<Stack direction="column" spacing={2}>
						<TextField
							fullWidth required
							size="small"
							label={t("settings.oauth2.entraId")}
							value={oauth2.entraId}
							onChange={v => handleOauth2Change('entraId', v)}
							helperText={
								<span>
								{t("settings.oauth2.entraIdHint.leading")}{" "}
									<Link
										target="_blank"
										href="https://entra.microsoft.com/#view/Microsoft_AAD_IAM/TenantOverview.ReactView"
									>
									{t("settings.oauth2.entraIdHint.trailing")} <OpenInNewIcon fontSize="inherit" />
								</Link>
							</span>
							}
						/>
						<TextField
							fullWidth required
							size="small"
							label="Client ID"
							value={oauth2.clientId}
							onChange={v => handleOauth2Change('clientId', v)}
						/>
						<TextField
							fullWidth required
							size="small"
							label="Client Secret"
							value={oauth2.clientSecret}
							onChange={v => handleOauth2Change('clientSecret', v)}
						/>
						<TextField
							fullWidth required
							size="small"
							label={t('tenants.emailSender')}
							value={oauth2.smtpUser}
							onChange={v => handleOauth2Change('smtpUser', v)}
						/>
					</Stack>
				)}
			</CardContent>
			<CardActions>
				{editMode && (
					<Fragment>
						<Button onClick={handleSave} disabled={!oauth2Configured}>{t("save")}</Button>
						<Button onClick={handleEditModeClose}>{t("cancel")}</Button>
					</Fragment>
				)}
				{!editMode && (
					<Fragment>
						<Button onClick={handleEditModeOpen}>{t("edit")}</Button>
						<Button color="error" disabled={!oauth2Configured} onClick={() => setRemovalPrompt(true)}>{t("delete")}</Button>
						<ConfirmDialog
							open={removalPrompt}
							onClose={() => setRemovalPrompt(false)}
							title={t('settings.oauth2.removePrompt.title')}
							cancel={t('cancel')}
							onConfirm={handleRemoval}
						>
							{t('settings.oauth2.removePrompt.message')}
						</ConfirmDialog>
					</Fragment>
				)}
			</CardActions>
		</Card>
	);
}
